@import '~antd/dist/antd.css';

body > #root > div {
  height: 100vh;
}

.App {
  text-align: center;
}
.text-danger {
  color: #DC3545 !important;
}
.bg-img {
  /* The image used */
  background-image: url("common/assets/images/background-img.jpg");

  /* Control the height of the image */
  min-height: 380px;

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.container {
  position: absolute;

    width: 350px;
    height: 320px;
    padding: 50px;
    background-color: white;
    /* Center form on page horizontally & vertically */
    top: 45%;
    left: 48%;
    margin-top: -150px;
    margin-left: -150px;
 
}

.emptyDescription .ant-empty-description:after { 
  content: " available."; 
} 
.emptySubscriptionTable .ant-empty-description:after { 
  content: " available. Please Select above dropdowns to view Subscriptions"; 
} 

.btn {
  padding: 50px 0px;
  width:320px;
  margin-left:12%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
